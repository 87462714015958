import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import type { VideoData } from "../types";
import { Video } from "./Video";

type VideosProps = {
  videos: VideoData[];
};

export function Videos({ videos }: VideosProps): JSX.Element {
  return (
    <Box>
      <Typography variant="h2">Videos</Typography>
      {videos
        .sort((a, b) => a.startYear - b.startYear)
        .map((videoData) => (
          <Box key={videoData.url} sx={{ marginBottom: 2 }}>
            <Video videoData={videoData} />
          </Box>
        ))}
    </Box>
  );
}
