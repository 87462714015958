import React, { useRef } from "react";
import ReactPlayer from "react-player";
import { useCurrentPlayingContext } from "../../hooks/use-current-playing-context";
import "./Player.css";

type PlayerProps = {
  url: string;
};

export function Player({ url }: PlayerProps): JSX.Element {
  const { playingUrl, setPlayingUrl } = useCurrentPlayingContext();

  const handlePlay = () => setPlayingUrl(url);
  const isPlaying = url === playingUrl;

  return (
    <div className="player-wrapper">
      <ReactPlayer
        config={{
          youtube: {
            playerVars: { modestbranding: 1, rel: 0 },
          },
        }}
        className="react-player"
        controls={true}
        height="100%"
        loop={true}
        onPlay={handlePlay}
        pip={true}
        playing={isPlaying}
        url={url}
        width="100%"
      />
    </div>
  );
}
