import React, { useState } from "react";
import { useCallback, useContext } from "react";

type CurrentPlayingContextProps = {
  playingUrl: string | undefined;
  setPlayingUrl: (url: string | undefined) => void;
};

const CurrentPlayingContext = React.createContext<CurrentPlayingContextProps>({
  playingUrl: undefined,
  setPlayingUrl: () => {},
});

type CurrentPlayingContextProviderProps = {
  children: JSX.Element;
};

export const CurrentPlayingContextProvider = ({
  children,
}: CurrentPlayingContextProviderProps): JSX.Element => {
  const [playingUrl, setPlayingUrl] = useState<string | undefined>();

  return (
    <CurrentPlayingContext.Provider value={{ playingUrl, setPlayingUrl }}>
      {children}
    </CurrentPlayingContext.Provider>
  );
};

export function useCurrentPlayingContext() {
  const context = useContext(CurrentPlayingContext);

  if (!context) {
    // TODO: This doesn't work for some reason...
    throw new Error("Must use a CurrentPlayingProvider");
  }

  return context;
}
