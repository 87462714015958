import { useEffect } from "react";
import ReactDOM from "react-dom";
import { Videos } from "./components/Videos";
import { useVideos } from "./hooks/use-videos";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material";
import { CurrentPlayingContextProvider } from "./hooks/use-current-playing-context";

const VIDEOS_API = "/api/videos/";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App(): JSX.Element {
  const videos = useVideos(VIDEOS_API);
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container>
        <Typography variant="h1">Video Archive Project</Typography>
        <CurrentPlayingContextProvider>
          <Videos videos={videos} />
        </CurrentPlayingContextProvider>
      </Container>
    </ThemeProvider>
  );
}

const app = document.getElementById("root");
ReactDOM.render(<App />, app);
