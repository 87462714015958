import { useEffect, useState } from "react";
import camelcaseKeysDeep from "camelcase-keys-deep";
import type { VideoData } from "../types";

export function useVideos(url: string): VideoData[] {
  const [videos, setVideos] = useState<VideoData[]>([]);
  useEffect(() => {
    const res = fetch(url)
      .then((res) => res.json())
      .then((json) => {
        setVideos(camelcaseKeysDeep(json) as VideoData[]);
      })
      .catch((err) => {
        console.error(`Could not fetch videos: ${err}`);
      });
  }, [url]);

  return videos;
}
