import type { CaptureData } from "../types";

type CaptureProps = {
  captureData: CaptureData;
};

function getPlaybackDeviceText(
  playbackDevice: string,
  tbc: boolean,
  calibration: boolean,
  r3: boolean
): string {
  let playbackDeviceInfo = "";
  if (tbc !== null) {
    playbackDeviceInfo += `TBC: ${tbc ? "Yes" : "No"}`;
  }
  if (calibration !== null) {
    playbackDeviceInfo += `Calibration: ${calibration ? "Yes" : "No"}`;
  }
  if (r3 !== null) {
    playbackDeviceInfo += `R3: ${r3 ? "Yes" : "No"}`;
  }

  let playbackDeviceText = playbackDevice;
  if (playbackDeviceInfo) {
    playbackDeviceText += ` (${playbackDeviceInfo})`;
  }

  return playbackDeviceText;
}

export function Capture({ captureData }: CaptureProps): JSX.Element {
  const {
    captureFormat,
    captureDevice,
    passthru,
    playbackDevice,
    tbc,
    calibration,
    r3,
  } = captureData;

  const playbackDeviceText = getPlaybackDeviceText(
    playbackDevice,
    tbc,
    calibration,
    r3
  );

  return (
    <>
      {[playbackDeviceText, passthru, captureDevice, captureFormat]
        .filter(Boolean)
        .join(" \u279C ")}
    </>
  );
}
