import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Button,
  CardMedia,
  CardContent,
  CardActions,
  Card,
} from "@mui/material";
import type { CaptureData, VideoData } from "../types";
import { Capture } from "./Capture";
import { useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player/youtube";

import { Player } from "./Player/Player";

type VideoProps = {
  videoData: VideoData;
};

export function Video({ videoData }: VideoProps): JSX.Element {
  const [captureIdx, setCaptureIdx] = useState<number | undefined>();
  const {
    captures,
    comments,
    description,
    endYear,
    info,
    labelText,
    startYear,
    tapeNumber,
  } = videoData;

  useEffect(() => {
    if (captures.length > 0) {
      setCaptureIdx(0);
    }
  }, [captures]);

  const captureSelect = useMemo(
    () => (
      <FormControl fullWidth>
        <InputLabel id="captures-select-label">Captures</InputLabel>
        <Select
          labelId="captures-select-label"
          value={captureIdx}
          id="captures-select"
          label="Captures"
          onChange={(event) => setCaptureIdx(event.target.value as number)}
        >
          {captures.map((capture, idx) => (
            <MenuItem key={capture.url} value={idx}>
              Capture {idx + 1}: <Capture captureData={capture} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    [captureIdx, captures, setCaptureIdx]
  );

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {startYear} - {endYear}: {description}{" "}
          {tapeNumber && `(Tape Number: ${tapeNumber})`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {info}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Label Text: {labelText}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Comments: {comments}
        </Typography>
      </CardContent>
      {captureIdx !== undefined && captures[captureIdx]?.youtubeEmbedUrl && (
        <CardMedia>
          <Player url={captures[captureIdx].youtubeEmbedUrl} />
        </CardMedia>
      )}
      {captureIdx !== undefined && <CardContent>{captureSelect}</CardContent>}
      <CardActions>
        <Button size="small">Share</Button>

        <Button
          size="small"
          href="https://docs.google.com/document/d/12Oc76Oy5LF3k4lLNYS0LxIwXg9PO_AS5ZBYrKVeg_-w/edit?usp=sharing"
          target="_blank"
        >
          Comments / Corrections
        </Button>
      </CardActions>
    </Card>
  );
}
